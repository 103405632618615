// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
// (function($) {
//     'use strict';

//     if (!Foundation.stylesheet) {
//         Foundation._style_element = $('<style></style>').appendTo('head')[0];
//         Foundation.stylesheet = Foundation._style_element.styleSheet;

//         if (Foundation.stylesheet) {
//             Foundation.stylesheet.cssRules = {
//                 length: 0
//             };

//             Foundation.stylesheet.insertRule = function(rule, index) {
//                 var media;
//                 var mediaMatch;
//                 var mediaRegex;
//                 var namespace;
//                 var ruleMatch;
//                 var ruleRegex;
//                 mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 mediaMatch = mediaRegex.exec(rule);
//                 media = '';

//                 if (mediaMatch) {
//                     media = '@media ' + mediaMatch[1] + ' ';
//                     rule = mediaMatch[2];
//                 }

//                 ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 ruleMatch = ruleRegex.exec(rule);
//                 namespace = '' + media + ruleMatch[1];
//                 rule = ruleMatch[2];

//                 return this.addRule(namespace, rule);
//             };
//         } else if (window.console) {
//             console.log('Could not fix Foundation CSS rules...');
//         }
//     }
// })(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

// Safely initiate foundation now
$(document).foundation({
    tab: {
      callback : function (tab) {
        console.log(tab);
      }
    }
  });

// the rest of your code

$(window).load(function() {
    'use strict';

    // accessible image sprites
    HCTest();
});

// mobile-nav
(function() {

    //var mqm = window.matchMedia('(max-width: 64.0625em)');
    var mqm = window.matchMedia('(max-width: 62.45em)');

    // get the button and menu nodes
    var button = document.querySelector('[aria-label="site"] button');
    if (button == null) {
      return;
    }
    var menu = button.nextElementSibling;


    // set initial (closed menu) states
    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    if (mqm.matches) {
        menu.hidden = true;
    }

    $( window ).resize(function() {
        if (window.matchMedia('(min-width: 620px)').matches) {
            menu.hidden = false;
        }
    });

    button.addEventListener('click', function() {
        // toggle menu visibility
        var expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', String(!expanded));
        menu.hidden = expanded;
    });

})();

$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

    // hamburger
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function(e) {
      $hamburger.toggleClass("is-active");
      $('.menu').toggleClass("js-open");
    });

    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });

    // Match Height
    $('.member__wrapper').matchHeight();
    $('.curs').matchHeight();
    $('.donate-block').matchHeight();
    $('.proj-block').matchHeight();
    $('.news-item').matchHeight();
    $('.info-box-research').matchHeight();
    $('.news-dest__img, .news-dest__container').matchHeight();
    if ($('.work-wrapper').lenght > 1) {
	    $('.work-wrapper').parent().matchHeight();
	    $('.work-wrapper').first().parent().addClass('js-border');

    }
    $('.donate-wrapper').parent().matchHeight();
    $('.donate-wrapper').first().parent().addClass('js-border');

    // donacions projectes
    $('.proj-block').prepend('<div class="proj-overlay"></div>');

    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }

    //Form
    $("input:radio[name=59], input:radio[name=44]").on('click', function() {
    var value = $(this).val();
    if (value == 'Un altre import') {
       $("#quantitat-altra-aportacio").removeClass('disabled');
       $("#quantitat-altra-aportacio").removeAttr('disabled');
       $("#altra-aportacio").show();
       $("#quantitat-altra-aportacio").focus();
    }else{
      $("#quantitat-altra-aportacio").addClass('disabled');
        $("#quantitat-altra-aportacio").attr('disabled','');
        $("#altra-aportacio").hide();
    }
});


$(window).bind('load', function()
{
  var value =  $("input:radio[name=59]:checked, input:radio[name=44]:checked").val();
  if (value == 'Un altre import') {
         $("#quantitat-altra-aportacio").removeClass('disabled');
         $("#quantitat-altra-aportacio").removeAttr('disabled');
         $("#altra-aportacio").show();
         $("#quantitat-altra-aportacio").focus();
      }else{
        $("#quantitat-altra-aportacio").addClass('disabled');
          $("#quantitat-altra-aportacio").attr('disabled','');
          $("#altra-aportacio").hide();
      }
} );

    // smooth scroll top
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".top").fadeIn();
      } else {
          $(".top").fadeOut();
      }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"],[href="#s"],[href="#panel1"],[href="#panel2"],[href="#panel3"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });

    // sliders
    $('.home-slider').slick({
        // dots: true,
        autoplay: true,
        autoplaySpeed: 12000
    });
    $('.funding-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          }
        ]
    });


    // Menú dropdowns (http://heydonworks.com/practical_aria_examples/#submenus)
    $('.site-menu ul').prev('a')
      .attr('aria-haspopup', 'true')
      // .append('<span aria-hidden="true"> &#x25be;</span>');

    $('.site-menu > li > a').on('focus', function(e) {
      hideSubmenu($('[aria-label="submenu"]'));
    });

    var showSubmenu = function(dropdown) {
      dropdown.attr('aria-hidden', 'false');
    };

    var hideSubmenu = function(dropdown) {
      dropdown.attr('aria-hidden', 'true');
    };

    $('[aria-haspopup]').on('click', function(e) {
      //afegides 3 primeres línies per menú en ipad, sinó es mostraven sempre oberts
      e.preventDefault();
      hideSubmenu($('[aria-label="submenu"]'));
      $('.site-menu a').removeClass('is-open');
      var submenu = $(this).next();
      showSubmenu(submenu);
      //$(submenu).find('li:first-child a').focus();
      $(this).addClass('is-open');
      return false;
    });
    //només aplicar en cas de menú desktop, sinó en android no obria
    if (window.matchMedia('(min-width: 1000px)').matches) {
      $('[aria-haspopup]').hover(function() {
        showSubmenu($(this).next());
        $(this).off('click');
      });
    }
    //mostrar el submenú desplegat  en mòbils si estas a l'apartat
    if (window.matchMedia('(max-width: 999px)').matches) {
      showSubmenu($('.active a[aria-haspopup]').next());
    }

    $('[aria-haspopup]').parents('li').mouseleave(function() {
      hideSubmenu($(this).find('[aria-label="submenu"]'));
    });

    $('.site-menu>li').hover(function() {
      if ($(this).find('[aria-label="submenu"]').attr('aria-hidden') == 'false' ) {
        $(this).addClass('is-hovered');
      } else {
        $(this).removeClass('is-hovered');
      }
    });

    // search desktop
    $('.lupa-s').click(function() {
      /*if ($('.s--desktop').hasClass('is-visible')) {
        $('.s--desktop').removeClass('is-visible').addClass('is-hidden');
      } else {
        $('.s--desktop').removeClass('is-hidden').addClass('is-visible');
      }
      return false;*/
      $('#cercador-box').fadeIn();
      $( "input.cercar" ).focus();
        return false;
    });
    $('#s-1').click(function(e){
        e.stopPropagation();
    });
    $("#tancar-cerca, #cercador-box").click(function() {
        $("#cercador-box").hide();
        return false;
    });
    $('body').click(function(){
      $('.s--desktop').removeClass('is-visible').addClass('is-hidden');
    });
    $('.s--desktop').click(function(e){
      e.stopPropagation();
    });


    if ($('#researchResults').length) {
        function resultsAddLoader()
        {
            $('#researchResults a.more').remove();
            $("#researchResults").append('<div class="loading text-center"><img src="/media/img/loading.svg" width="70" /></div>');
        }

        function resultsRemoveLoader()
        {
            $("#researchResults .loading").remove();
        }

        $(document).on('click', 'a.more', function(e){
            e.preventDefault();

            resultsAddLoader();

            $.ajax({
                type: "GET",
                url: $(this).attr('href'),
                success: function(data) {

                    $("#researchResults").append(data);
                    $('.work-wrapper').parent().matchHeight();
                    resultsRemoveLoader();
                }
            });
            return false;
        });
    }

    if ($('#researchResultsPublications').length) {
        function resultsPublicationsAddLoader()
        {
            $('#researchResultsPublications a.more').remove();
            $("#researchResultsPublications").append('<div class="loading text-center"><img src="/media/img/loading.svg" width="70" /></div>');
        }

        function resultsPublicationsRemoveLoader()
        {
            $("#researchResultsPublications .loading").remove();
        }

        $(document).on('click', 'a.morePublications', function(e){
            e.preventDefault();

            resultsPublicationsAddLoader();

            $.ajax({
                type: "GET",
                url: $(this).attr('href'),
                success: function(data) {

                    $("#researchResultsPublications").append(data);
                    $.fn.matchHeight._maintainScroll = true
                    $('.work-wrapper').parent().matchHeight();
                    resultsPublicationsRemoveLoader();
                }
            });
            return false;
        });
    }

    if ($('#researchResultsProjects').length) {
        function resultsProjectsAddLoader()
        {
            $('#researchResultsProjects a.more').remove();
            $("#researchResultsProjects").append('<div class="loading text-center"><img src="/media/img/loading.svg" width="70" /></div>');
        }

        function resultsProjectsRemoveLoader()
        {
            $("#researchResultsProjects .loading").remove();
        }

        $(document).on('click', 'a.moreProjects', function(e){
            e.preventDefault();

            resultsProjectsAddLoader();

            $.ajax({
                type: "GET",
                url: $(this).attr('href'),
                success: function(data) {

                    $("#researchResultsProjects").append(data);
                    $.fn.matchHeight._maintainScroll = true
                    $('.work-wrapper').parent().matchHeight();
                    resultsProjectsRemoveLoader();
                }
            });
            return false;
        });
    }

    // ScrollToFixed
    function mediaqueryresponse1(mql){
     if (mql.matches){ // if media query matches
        $('.main-nav').scrollToFixed();
     }
     else {
        $('.main-nav').trigger('detach.ScrollToFixed');
     }
    }
    var mql = window.matchMedia('screen and (min-width: 64.0625em)')
    mediaqueryresponse1(mql) // call listener function explicitly at run time
    mql.addListener(mediaqueryresponse1) // attach listener function to listen in on state changes

    // Progressive collapsibles (per mòbil)
    function mediaqueryresponse2(mqs){
     if (mqs.matches){ // if media query matches
        $('.collapsible__title').each(function() {

          var $this = $(this);

          // create unique id for a11y relationship

          var id = 'collapsible-' + $this.index();

          // wrap the content and make it focusable

          $this.nextUntil('.collapsible__title').wrapAll('<div class="collapsible__body id="'+ id +'" aria-hidden="true">');
          var panel = $this.next();

          // Add the button inside the <h2> so both the heading and button semantics are read

          $this.wrapInner('<button aria-expanded="false" aria-controls="'+ id +'">');
          var button = $this.children('button');

          // Toggle the state properties

          button.on('click', function() {
            var state = $(this).attr('aria-expanded') === 'false' ? true : false;
            $(this).attr('aria-expanded', state);
            panel.attr('aria-hidden', !state);
            $(this).parent().toggleClass('js-open');
          });

        });
     }
     else {
        $('.collapsible__title button, .collapsible__body').each(function() {
          $(this).contents().unwrap();
        });
      }
    }
    var mqs = window.matchMedia('screen and (max-width: 740px)')
    mediaqueryresponse2(mqs) // call listener function explicitly at run time
    mqs.addListener(mediaqueryresponse2) // attach listener function to listen in on state changes

    // desplegables
    $('.toggle').click(function() {
      $(this).toggleClass('close');
      $(this).next().toggleClass('is-hidden');
      return false;
    });

    //Recaptcha
    $('.houdiniForm').submit(function(event) {
        var form = $(this);
        event.preventDefault();
        console.log('recaptcha');
        grecaptcha.ready(function() {
            grecaptcha.execute('6LeI_wgbAAAAAMoC8rHO8jRKykI5ia8WyegGJo3Q', {action: 'formsubmit'}).then(function(token) {
                form.prepend('<input type="hidden" name="token" value="' + token + '">');
                form.prepend('<input type="hidden" name="action" value="formsubmit">');
                if(form.valid()){
                    form.unbind('submit').submit();
                }
            });;
        });
    });

});
